import './distributiva_entorno.scss';

import React from 'react';
import { graphql } from 'gatsby';
import Loki from '../src/components/Loki/lokipane';
import { Freya } from '../src/components/Freya/Freya';
import Tyr from '../src/components/Tyr/Tyr';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import Hod from '../src/components/Hod/Hod';
import SEO from '../src/helpers/seo';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import tt from '../src/helpers/translation';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query entornoQuery($locale: String!) {
    allEntornoSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allEntornoRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }

    allEntornoHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
    allEntornoImageHeaderBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allEntornoDiscoverTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allEntornoDiscoverParksBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          title_url
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          button1 {
            cta
            ctaText
            size
            color
          }
        }
      }
    }
    allEntornoFreya(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imgsize
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allEntornoLokiBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    entornoAloneImage {
      localImage {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allEntornoBreadCrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
  }
`;

class DistEntorno extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allEntornoHeaderBlock.edges[0].node.title,
      image: {
        alt: this.props.data.allEntornoImageHeaderBlock.edges[0].node.alt,
        url: this.props.data.allEntornoImageHeaderBlock.edges[0].node.localImage.childImageSharp
          .fluid,
      },
    };

    const prepareForTyr = data => ({
      cards: data.edges.map(item => ({
        MainImage: item.node.localImage.childImageSharp.fluid,
        altImage: item.node.image_alt,
        Title: item.node.title,
        TitleURL: item.node.TitleURL,
        button1: item.node.button1,
      })),
    });

    const h2Data = {
      title: this.props.data.allEntornoDiscoverTitle.edges[0].node.title,
      description: this.props.data.allEntornoDiscoverTitle.edges[0].node.description,
    };

    const freyaData = {
      title: tt('DESCUBRE LAS POBLACIONES CERCANAS', this.props.pageContext.locale),
      isSlider: true,
      cards: this.props.data.allEntornoFreya.edges.map(item => ({
        image: item.node.localImage.childImageSharp.fluid,
        altImage: item.node.image_alt,
        title: item.node.title,
        description: item.node.description,
        imgsize: 'big',
        buttons: {
          cta: item.node.buttons.cta,
          size: item.node.buttons.size,
          color: item.node.buttons.color,
          ctaText: item.node.buttons.ctaText,
        },
      })),
    };

    const prepareForLoki = () => ({
      title: tt('GALERIA DE FOTOS', this.props.pageContext.locale),
      arrayImg: this.props.data.allEntornoLokiBlock.edges
        .map(block => ({
          img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
          altImage: block.node.image_alt,
        }))
        .filter(item => item.img !== null),
      bigArrayImg: this.props.data.allEntornoLokiBlock.edges
        .map(block => ({
          img: block.node.localImage ? block.node.localImage.childImageSharp.fluid : null,
          altImage: block.node.image_alt,
        }))
        .filter(item => item.img !== null),
    });

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allEntornoSeoData.edges[0].node._0.title}
          description={this.props.data.allEntornoSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="entornos-content">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allEntornoBreadCrumbBlock.edges[0].node.name}
              />
              <Hod
                data={this.props.data.allEntornoRichData.edges[0].node.rich_markup}
                url={this.props.pageContext.url}
                img={
                  this.props.data.allEntornoImageHeaderBlock.edges[0].node.localImage
                    .childImageSharp.fluid
                }
              />
              <H2 data={h2Data} />
              <Tyr data={prepareForTyr(this.props.data.allEntornoDiscoverParksBlock)} />
              <div className="grey-content">
                <H2 data={freyaData} />
                <Freya data={freyaData} />
              </div>
              <Loki data={prepareForLoki()} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default DistEntorno;
